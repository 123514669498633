import "./App.css";
import { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Fab from "@mui/material/Fab";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

import ShampoosCover from "./static/shampoos/cover.webp";
import ConditionersCover from "./static/conditioners/cover.webp";
import IdealanParCover from "./static/pairs/idealan-par.webp";
import AccessoriesCover from "./static/accessories/cover.webp";
import SetsCover from "./static/sets/cover.webp";

import Header from "./components/Header/Header";
import { analytics, getAllDocs } from "./firebase.js";
import { discountCondition } from "./discount";
import MetaPixel from "./utils/MetaPixel.js";
import { logEvent } from "firebase/analytics";
import { processBasketForAnalytics } from "./utils/BasketUtils.js";

const Footer = lazy(() => import("./components/Footer/Footer"));
const Product = lazy(() => import("./components/Product/Product"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const About = lazy(() => import("./components/About/About"));
const Home = lazy(() => import("./components/Home/Home"));
const ProductCategories = lazy(() => import("./components/Product/ProductCategories"));
const Checkout = lazy(() => import("./components/Checkout/Checkout"));

function App() {
  const [shampooList, setShampooList] = useState([]);
  const [conditionerList, setConditionerList] = useState([]);
  const [pairList, setPairList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [accessoryList, setAccessoryList] = useState([]);
  const [setList, setSetList] = useState([]);
  const [basket, setBasket] = useState([]);

  const getFooterLinks = (elemList) => elemList.map((el) => [el.footerTitle, el.slug]);

  const openBasketModal = () => {
    const basketModal = document.getElementById("basket-modal");
    basketModal.showModal();
    logEvent(analytics, "view_cart", processBasketForAnalytics(basket, "RSD"));
  };

  const closeBasketModal = () => {
    const basketModal = document.getElementById("basket-modal");
    basketModal.close();
  };

  const modalClickAway = (e) => {
    const basketModal = document.getElementById("basket-modal");
    const modalDimensions = basketModal.getBoundingClientRect();
    if (e.clientX < modalDimensions.left || e.clientX > modalDimensions.right || e.clientY < modalDimensions.top || e.clientY > modalDimensions.bottom) {
      basketModal.close();
    }
  };

  const applyDiscount = (price) => {
    if (discountCondition(basket)) {
      return price * 0.9;
    }

    return price;
  };

  const getTotalPrice = (type) => {
    if (type === "srb") {
      return applyDiscount(basket.reduce((acc, curr) => acc + curr.quantity * parseFloat(curr.priceSrb), 0)).toFixed(2);
    } else {
      return applyDiscount(basket.reduce((acc, curr) => acc + curr.quantity * parseFloat(curr.priceOther), 0)).toFixed(2);
    }
  };

  const incrementBasketQuantity = (index, increment) => {
    let tempBasket = basket.slice();
    basket[index]["quantity"] += increment;

    setBasket(tempBasket);
    sessionStorage.setItem("basket", JSON.stringify(tempBasket));
  };

  const removeFromBasket = (index) => {
    logEvent(analytics, "remove_from_cart", processBasketForAnalytics([basket[index]], "RSD"));

    let tempBasket = basket.slice();
    tempBasket.splice(index, 1);

    // applyPairDiscount(tempBasket);
    setBasket(tempBasket);
    sessionStorage.setItem("basket", JSON.stringify(tempBasket));
  };

  useEffect(() => {
    getAllDocs("shampoos", setShampooList);
    getAllDocs("conditioners", setConditionerList);
    getAllDocs("pairs", setPairList);
    getAllDocs("other", setOtherList);
    getAllDocs("discounts", setDiscountList);
    getAllDocs("accessories", setAccessoryList);
    getAllDocs("sets", setSetList);

    if (sessionStorage.getItem("basket")) {
      setBasket(JSON.parse(sessionStorage.getItem("basket")));
    }

    return () => {
      setShampooList([]);
      setConditionerList([]);
      setPairList([]);
      setOtherList([]);
      setDiscountList([]);
      setAccessoryList([]);
      setSetList([]);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <MetaPixel />
        <Header />
        <Suspense>
          <Routes>
            <Route path="/" element={<Home discounts={discountList} />} />
            <Route path="/o-nama" element={<About />} />
            <Route path="/kontakt" element={<Contact />} />
            {shampooList.map((el) => (
              <Route
                path={el.slug}
                element={
                  <Product
                    title={el.title}
                    subtitle={el.subtitle}
                    image={el.pictureUrl}
                    description={el.description}
                    priceSrb={el.pricesRsd.map((price) => price.split("="))}
                    priceOther={el.pricesEur.map((price) => price.split("="))}
                    ingredients={el.ingredients}
                    pros={el.pros.concat(el.cons)}
                    instructions={el.instructions}
                    basketList={basket}
                    basketListSetter={setBasket}
                  />
                }
              />
            ))}
            {conditionerList.map((el) => (
              <Route
                path={el.slug}
                element={
                  <Product
                    title={el.title}
                    subtitle={el.subtitle}
                    image={el.pictureUrl}
                    description={el.description}
                    priceSrb={el.pricesRsd.map((price) => price.split("="))}
                    priceOther={el.pricesEur.map((price) => price.split("="))}
                    ingredients={el.ingredients}
                    pros={el.pros.concat(el.cons)}
                    instructions={el.instructions}
                    basketList={basket}
                    basketListSetter={setBasket}
                  />
                }
              />
            ))}
            {pairList.map((el) => (
              <Route
                path={el.slug}
                element={
                  <Product
                    title={el.title}
                    subtitle={el.subtitle}
                    image={el.pictureUrl}
                    priceSrb={el.pricesRsd.map((price) => price.split("="))}
                    priceOther={el.pricesEur.map((price) => price.split("="))}
                    pros={el.pros}
                    description={el.description}
                    basketList={basket}
                    basketListSetter={setBasket}
                  />
                }
              />
            ))}
            {otherList.map((el) => (
              <Route
                path={el.slug}
                element={
                  <Product
                    title={el.title}
                    subtitle={el.subtitle}
                    image={el.pictureUrl}
                    description={el.description}
                    priceSrb={el.pricesRsd.map((price) => price.split("="))}
                    priceOther={el.pricesEur.map((price) => price.split("="))}
                    ingredients={el.ingredients}
                    pros={el.pros.concat(el.cons)}
                    instructions={el.instructions}
                    basketList={basket}
                    basketListSetter={setBasket}
                  />
                }
              />
            ))}
            {/* {discountList.map((el) => (
              <Route
                path={el.slug}
                element={
                  <Product
                    title={el.title}
                    subtitle={el.subtitle}
                    image={el.pictureUrl}
                    pros={[]}
                    priceSrb={el.pricesRsd.map((price) => price.split("="))}
                    priceOther={el.pricesEur.map((price) => price.split("="))}
                    discount={true}
                    basketList={basket}
                    basketListSetter={setBasket}
                  />
                }
              />
            ))} */}
            {accessoryList
              .filter((el) => el.enabled)
              .map((el) => (
                <Route
                  path={el.slug}
                  element={
                    <Product
                      title={el.title}
                      subtitle={el.subtitle}
                      image={el.pictureUrl}
                      pros={[]}
                      priceSrb={el.pricesRsd.map((price) => price.split("="))}
                      priceOther={el.pricesEur?.map((price) => price.split("="))}
                      basketList={basket}
                      basketListSetter={setBasket}
                    />
                  }
                />
              ))}
            {setList.map((el) => (
              <Route
                path={el.slug}
                element={
                  <Product
                    title={el.title}
                    subtitle={el.subtitle}
                    image={el.pictureUrl}
                    pros={[]}
                    description={el.description}
                    priceSrb={el.pricesRsd.map((price) => price.split("="))}
                    priceOther={el.pricesEur.map((price) => price.split("="))}
                    basketList={basket}
                    basketListSetter={setBasket}
                  />
                }
              />
            ))}
            <Route
              path="/proizvodi"
              element={
                <ProductCategories
                  items={[
                    { link: "samponi", image: ShampoosCover, heading: "ŠAMPONI" },
                    { link: "balzami", image: ConditionersCover, heading: "BALZAMI" },
                    { link: "idealan-par", image: IdealanParCover, heading: "IDEALAN PAR - ŠAMPON I BALZAM" },
                    { link: "dodatna-oprema", image: AccessoriesCover, heading: "DODATNA OPREMA" },
                    { link: "setovi", image: SetsCover, heading: "SETOVI KOZMETIKE\n(NA POPUSTU 10%)" },
                  ].concat(otherList.map((el) => ({ link: el.slug, image: el.coverPhoto, heading: el.menuTitle })))}
                />
              }
            />
            <Route
              path="/samponi"
              element={<ProductCategories items={shampooList.map((el) => ({ link: el.slug, image: el.pictureUrl, heading: el.menuTitle }))} />}
            />
            <Route
              path="/balzami"
              element={<ProductCategories items={conditionerList.map((el) => ({ link: el.slug, image: el.pictureUrl, heading: el.menuTitle }))} />}
            />
            <Route
              path="/idealan-par"
              element={<ProductCategories items={pairList.map((el) => ({ link: el.slug, image: el.pictureUrl, heading: el.menuTitle }))} />}
            />
            <Route
              path="/dodatna-oprema"
              element={
                <ProductCategories items={accessoryList.filter((el) => el.enabled).map((el) => ({ link: el.slug, image: el.pictureUrl, heading: el.menuTitle }))} />
              }
            />
            <Route path="/setovi" element={<ProductCategories items={setList.map((el) => ({ link: el.slug, image: el.pictureUrl, heading: el.menuTitle }))} />} />
            <Route path="/checkout" element={<Checkout basketList={basket} basketListSetter={setBasket} />} />
          </Routes>

          <dialog id="basket-modal" onClick={modalClickAway}>
            <h2>VAŠA KORPA</h2>
            {basket.length > 0 ? (
              basket.map((el, i, arr) => (
                <div className="basket-product">
                  <div className="basket-product-left">
                    <h3>{el.title}</h3>
                    <p>
                      Odabrano pakovanje: <span className="bold-text">{el.selectedPackage}</span>
                    </p>
                    <div className="quantity-wrapper">
                      Uneta količina:
                      <div className="quantity">
                        {el.title.includes("(POPUST)") ? (
                          ""
                        ) : (
                          <button
                            onClick={() => {
                              if (el.quantity > 1) {
                                incrementBasketQuantity(i, -1);
                              }
                            }}
                          >
                            -
                          </button>
                        )}
                        <p className="bold-text">{el.quantity}</p>
                        {el.title.includes("(POPUST)") ? (
                          ""
                        ) : (
                          <button
                            onClick={() => {
                              incrementBasketQuantity(i, 1);
                            }}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                    <p>
                      Cena stavke: <span className="bold-text">{(el.quantity * parseFloat(el.priceSrb)).toFixed(2)} RSD</span> /{" "}
                      <span className="bold-text">{(el.quantity * parseFloat(el.priceOther)).toFixed(2)} €</span>
                    </p>
                  </div>
                  <div className="basket-product-right">
                    <img alt={el.title} src={el.image} />
                    <button onClick={() => removeFromBasket(i)}>x</button>
                  </div>
                </div>
              ))
            ) : (
              <p>Korpa je trenutno prazna.</p>
            )}
            <div className="total-price">
              Ukupna cena: {getTotalPrice("srb")} RSD / {getTotalPrice("other")} €
              {discountCondition(basket) ? <p style={{ fontSize: 12 }}>*Ostvarili ste popust od 10%</p> : ""}
              <div className="control-buttons">
                {basket.length > 0 ? (
                  <Link to="checkout" onClick={closeBasketModal}>
                    NARUČIVANJE
                  </Link>
                ) : (
                  ""
                )}
                <button onClick={closeBasketModal}>ZATVORI</button>
              </div>
            </div>
          </dialog>

          <Fab variant="extended" onClick={openBasketModal}>
            <ShoppingBasketIcon sx={{ mr: 1 }} />
            KORPA
          </Fab>
          <Footer otherFooterLinks={getFooterLinks(otherList)} />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
